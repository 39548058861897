import React, { ComponentProps } from 'react'
import styled from 'styled-components'
// @ts-expect-error
import colorContrast from 'color-contrast'

import {
  Box,
  Bullet,
  CheckIcon,
  Col,
  Inline,
  Row,
  SmallText,
  Spacer,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
  XIcon
} from '@te-digi/styleguide'
import tokens from '@te-digi/styleguide-tokens'

import { Content } from '../components/Content'
import { Section } from '../components/Section'

const DEPRECATED_TOKENS: string[] = []

const StyledBullet = styled(Bullet)<{ background: string }>`
  > div {
    background: ${props => props.background};
  }
`

const StyledSpacingBox = styled.div`
  background-color: ${tokens.color['neutral-4']};
  border-radius: 1px;
  float: left;
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const StyledShadow = styled.div<{ shadow: string }>`
  background-color: ${tokens.color.white};
  border-radius: ${tokens.radius.md};
  box-shadow: ${props => props.shadow};
  height: 4rem;
`

const StyledBox = styled(Box)<{ circle: boolean }>`
  height: 80px;
  width: ${props => props.circle && '80px'};
`

const capitalize = (text: string) =>
  text
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-')

const hexToRgb = (hex: string) => {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return (
    rgb &&
    `rgb(${parseInt(rgb[1], 16)}, ${parseInt(rgb[2], 16)}, ${parseInt(
      rgb[3],
      16
    )})`
  )
}

const AACompliant = ({
  background,
  foreground
}: {
  background: keyof typeof tokens.color
  foreground: keyof typeof tokens.color
}) => {
  const isAA =
    colorContrast(tokens.color[foreground], tokens.color[background]) >= 4.5

  return (
    <Inline
      alignItems="center"
      justifyContent="center"
    >
      {isAA ? (
        <CheckIcon
          block
          color="success"
        />
      ) : (
        <XIcon
          block
          color="danger"
        />
      )}
      <SmallText noMargin>AA</SmallText>
    </Inline>
  )
}

const getValue = (category: string, value: string) => {
  switch (category) {
    case 'color': {
      return (
        <>
          {value}
          <br />
          <Text color="dark">{hexToRgb(value)}</Text>
        </>
      )
    }

    case 'font-size':
    case 'radius':
    case 'space': {
      return (
        <>
          {value}
          {value.endsWith('rem') && (
            <>
              <br />
              <Text color="dark">{parseFloat(value) * 16}px</Text>
            </>
          )}
        </>
      )
    }

    default:
      return value
  }
}

const getExample = (category: string, token: string, value: string) => {
  switch (category) {
    case 'color': {
      return (
        <Row gap="xs">
          <Col xs="auto">
            <Box
              border="none"
              padding="sm"
              noMargin
            >
              <StyledBullet
                background={value}
                size="layout-lg"
              />
            </Box>
            <AACompliant
              background="white"
              foreground={token as any}
            />
          </Col>
          <Col xs="auto">
            <Box
              border="none"
              color="light"
              padding="sm"
              noMargin
            >
              <StyledBullet
                background={value}
                size="layout-lg"
              />
            </Box>
            <AACompliant
              background="neutral-2"
              foreground={token as any}
            />
          </Col>
        </Row>
      )
    }

    case 'font-size': {
      return (
        <Text
          lineHeight="xs"
          size={token as ComponentProps<typeof Text>['size']}
        >
          Lorem ipsum dolor sit amet.
        </Text>
      )
    }

    case 'font-weight': {
      return (
        <Text fontWeight={token as ComponentProps<typeof Text>['fontWeight']}>
          Lorem ipsum dolor sit amet.
        </Text>
      )
    }

    case 'gradient': {
      return (
        <StyledBullet
          background={value}
          size="layout-lg"
        />
      )
    }

    case 'line-height': {
      return (
        <Text
          as="p"
          lineHeight={token as ComponentProps<typeof Text>['lineHeight']}
        >
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien.
        </Text>
      )
    }

    case 'radius': {
      return (
        <StyledBox
          circle={token === 'circle'}
          noMargin
          padding="none"
          radius={token as ComponentProps<typeof Box>['radius']}
        />
      )
    }

    case 'shadow': {
      return <StyledShadow shadow={value} />
    }

    case 'space': {
      return (
        <StyledSpacingBox>
          <Spacer
            paddingLeft={token as ComponentProps<typeof Spacer>['paddingLeft']}
          />
        </StyledSpacingBox>
      )
    }

    default:
      return <Text color="dark">-</Text>
  }
}

const Page = () => (
  <Content
    heading="Design tokens"
    lead="Style Guiden komponentit käyttävät määriteltyjä design tokeneita, joilla varmennetaan käyttöliittymän yhdenmukaisuutta."
  >
    {Object.keys(tokens).map(category => (
      <Section
        key={category}
        title={capitalize(category)}
      >
        <Table divider="horizontal">
          <TableHeader>
            <TableRow>
              <TableHeaderCell style={{ width: '30%' }}>Token</TableHeaderCell>
              <TableHeaderCell style={{ width: '30%' }}>Arvo</TableHeaderCell>
              <TableHeaderCell>Esimerkki</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.keys(tokens[category as keyof typeof tokens]).map(token => {
              // @ts-expect-error
              const value = tokens[category][token]
              const sassVariable = `$te-${category}-${token}`

              return (
                !DEPRECATED_TOKENS.includes(sassVariable) && (
                  <TableRow key={sassVariable}>
                    <TableCell>
                      <Text fontWeight="semi-bold">{token}</Text>
                    </TableCell>
                    <TableCell>{getValue(category, value)}</TableCell>
                    <TableCell>{getExample(category, token, value)}</TableCell>
                  </TableRow>
                )
              )
            })}
          </TableBody>
        </Table>
      </Section>
    ))}
  </Content>
)

export default Page
